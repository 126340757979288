export const attachmentsConstants = {
    GET_FILE_REQUEST: 'GET_ATTACHMENTS_REQUEST',
    GET_FILE_SUCCESS: 'GET_ATTACHMENTS_SUCCESS',
    GET_FILE_FAILURE: 'GET_ATTACHMENTS_FAILURE',
  
    ADD_FILE_REQUEST: 'ADD_ATTACHMENTS_REQUEST',
    ADD_FILE_SUCCESS: 'ADD_ATTACHMENTS_SUCCESS',
    ADD_FILE_FAILURE: 'ADD_ATTACHMENTS_FAILURE',

    EDIT_FILE_REQUEST: 'EDIT_ATTACHMENTS_REQUEST',
    EDIT_FILE_SUCCESS: 'EDIT_ATTACHMENTS_SUCCESS',
    EDIT_FILE_FAILURE: 'EDIT_ATTACHMENTS_FAILURE',

    DELETE_FILE_REQUEST: 'DELETE_ATTACHMENTS_REQUEST',
    DELETE_FILE_SUCCESS: 'DELETE_ATTACHMENTS_SUCCESS',
    DELETE_FILE_FAILURE: 'DELETE_ATTACHMENTS_FAILURE',

};