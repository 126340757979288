export const expenseConstants = {
    GET_SHEETS_REQUEST: 'GET_EXPENSE_SHEETS_REQUEST',
    GET_SHEETS_SUCCESS: 'GET_EXPENSE_SHEETS_SUCCESS',
    GET_SHEETS_FAILURE: 'GET_EXPENSE_SHEETS_FAILURE',
  
    ADD_SHEETS_REQUEST: 'ADD_EXPENSE_SHEETS_REQUEST',
    ADD_SHEETS_SUCCESS: 'ADD_EXPENSE_SHEETS_SUCCESS',
    ADD_SHEETS_FAILURE: 'ADD_EXPENSE_SHEETS_FAILURE',

    EDIT_SHEETS_REQUEST: 'EDIT_EXPENSE_SHEETS_REQUEST',
    EDIT_SHEETS_SUCCESS: 'EDIT_EXPENSE_SHEETS_SUCCESS',
    EDIT_SHEETS_FAILURE: 'EDIT_EXPENSE_SHEETS_FAILURE',

    DELETE_SHEETS_REQUEST: 'DELETE_EXPENSE_SHEETS_REQUEST',
    DELETE_SHEETS_SUCCESS: 'DELETE_EXPENSE_SHEETS_SUCCESS',
    DELETE_SHEETS_FAILURE: 'DELETE_EXPENSE_SHEETS_FAILURE',

    GET_SUBMITTED_EXPENSES: "GET_SUBMITTED_EXPENSES",
    GET_SUBMITTED_EXPENSES_FAIL: "GET_SUBMITTED_EXPENSES_FAIL",

    UPDATE_EXPENSE_STATUS: "UPDATE_EXPENSE_STATUS",
    UPDATE_EXPENSE_STATUS_FAIL: "UPDATE_EXPENSE_STATUS_FAIL",

};