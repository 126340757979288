export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CHANGE_PWD_SUCCESS = "CHANGE_PWD_SUCCESS";
export const CHANGE_PWD_FAIL = "CHANGE_PWD_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const FETCH_PROJECTS_FAIL = "FETCH_PROJECTS_FAIL";
export const FETCH_PROJECT_BY_ID = "FETCH_PROJECT_BY_ID";
export const FETCH_PROJECT_BY_ID_FAIL = "FETCH_PROJECT_BY_ID_FAIL";
export const ADD_PROJECTS = "ADD_PROJECTS";
export const ADD_PROJECTS_FAIL = "ADD_PROJECTS_FAIL";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const EDIT_PROJECT_FAIL = "EDIT_PROJECT_FAIL";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL";
export const FETCH_PROJECT_EXPENSES = "FETCH_PROJECT_EXPENSES";
export const FETCH_PROJECT_EXPENSES_FAIL = "FETCH_PROJECT_EXPENSES_FAIL";

export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const FETCH_CLIENTS_FAIL = "FETCH_CLIENTS_FAIL";

export const FETCH_EMPLOYEES = "FETCH_EMPLOYEES";
export const FETCH_EMPLOYEES_FAIL = "FETCH_EMPLOYEES_FAIL";

export const ADD_CLIENT = "ADD_CLIENT";
export const ADD_CLIENT_FAIL = "ADD_CLIENT_FAIL";

export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL";

export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL";
